import type { ModalOptions } from 'learning/common/store-modules/modal.types.ts';
import type { ComponentProps } from 'sharedApp/types.ts';
import type { Component } from 'vue';

import useModalStore from 'learning/common/store-modules/modal.ts';

export default function useModal(options: Partial<ModalOptions> = {}) {
  const store = useModalStore();
  const { setModal, removeModal } = useModalStore();
  const defaultModalOptions: ModalOptions = {
    attachModalToTop: false,
    closeOnClickOutside: true,
    closeOnEsc: true,
    closeExisting: false,
    preventScroll: false,
    ...options,
  };

  let currentModalResolver: (() => void) | null = null;

  function closeCurrentModal(params: Record<string, unknown> = {}) {
    removeModal(params);
    if (currentModalResolver) {
      currentModalResolver();
      currentModalResolver = null;
    }
  }

  function openModal(
    modalComponentInstance: Component,
    props: ComponentProps = {},
    modalOptions: Partial<ModalOptions> = {},
  ): Promise<void> {
    return new Promise(resolve => {
      const modalOptionsMerged: ModalOptions = {
        ...defaultModalOptions,
        ...modalOptions,
      };

      if (store.displayingModal) {
        if (!modalOptionsMerged.closeExisting) {
          // One modal is already on display, modal on modal not allowed... for now
          return;
        }
        closeCurrentModal();
      }

      setModal(modalComponentInstance, props, modalOptionsMerged);
      currentModalResolver = resolve;
    });
  }

  return {
    openModal,
    removeModal: closeCurrentModal,
  };
}
