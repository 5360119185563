<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { storeToRefs } from 'pinia';

import type {
  DefaultToasterEmits,
  DefaultToasterProps,
} from 'sharedApp/components/base/toasters/kog-default-toaster.types.ts';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useToasterStore from 'sharedApp/store/toaster.ts';

const props = withDefaults(defineProps<DefaultToasterProps>(), {
  actionText: null,
  postActionText: null,
});
const emit = defineEmits<DefaultToasterEmits>();
const { toasters } = storeToRefs(useToasterStore());

const toasterTypes = {
  error: 'error',
  info: 'info',
  success: 'success',
};

const toaster = computed(() => (props.toasterId ? toasters.value.get(props.toasterId) : undefined));
const actionPerformed = computed(() => toaster.value?.actionPerformed);

const toasterComputedType = computed(() => {
  if (actionPerformed.value) return toasterTypes.success;
  if (toasterTypes[props.toasterType]) return props.toasterType;
  throw new Error('Undefined toaster type');
});

const toasterContent = computed(() => {
  return actionPerformed.value ? props.postActionText : props.toasterHtml;
});

function action() {
  if (toaster.value) {
    toaster.value.actionPerformed = true;
  }
  /**
   * Emitted when pressing the link in a toast
   */
  emit('toasterActionPerformed');
}

function onShiftMinusDown(e: KeyboardEvent) {
  if (e.shiftKey && e.code === 'Minus') {
    e.preventDefault();
    action();
  }
}

onMounted(() => {
  document.addEventListener('keydown', onShiftMinusDown);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', onShiftMinusDown);
});

function close() {
  if (!actionPerformed.value) {
    /**
     * Emitted when the toast action link was not pressed.
     */
    emit('toasterClosedNoActionPressed');
  }
  /**
   * Emitted when the toast is unmounting
   */
  emit('toasterDestroy');
}

function manualClose() {
  /**
   * Emitted when pressing the close button in a toast
   */
  emit('toasterClosedManually');
  close();
}

function mouseEnter() {
  /**
   * Emitted when the toast hovered with cursor or when being focused
   */
  emit('toasterMouseEnter');
}

function mouseLeave() {
  /**
   * Emitted when toast loses focus or when cursor move out from toast
   */
  emit('toasterMouseLeave');
}
</script>

<template>
  <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
  <div
    ref="root"
    :class="{
      'KogDefaultToaster-error': toasterComputedType === toasterTypes.error,
      'KogDefaultToaster-success': toasterComputedType === toasterTypes.success,
      'KogDefaultToaster-info': toasterComputedType === toasterTypes.info,
    }"
    role="alert"
    :aria-label="`${toasterType} message`"
    class="KogDefaultToaster flexContainer flexContainer-spaceBetween flexContainer-alignCenter padd-bottom-xs padd-top-xs padd-right-s padd-left-m"
    @mouseup="$event.stopPropagation()"
    @mouseenter="mouseEnter"
    @focusin="mouseEnter"
    @mouseleave="mouseLeave"
    @focusout="mouseLeave"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="flexChild-size-1 padd-right-1"
      v-html="toasterContent"
    />
    <!-- eslint-enable vue/no-v-html -->
    <span
      v-if="actionText && !actionPerformed"
      class="screenreader-only"
    >
      Press shift key together with minus key to {{ actionText }}
    </span>
    <div class="flexChild-center flexContainer flexContainer-alignCenter">
      <div
        v-if="actionText"
        class="inline-block cursorPointer margin-right-s"
        aria-hidden="true"
      >
        <button
          v-if="!actionPerformed"
          class="KogButtonLegacy--noStyle KogButtonLegacy--link KogDefaultToaster-actionButton"
          @click="action"
        >
          {{ actionText }}
        </button>
      </div>
      <button
        aria-label="Close"
        class="KogButtonLegacy--noStyle KogDefaultToaster-dismissButton"
        type="button"
        @click="manualClose"
      >
        <kog-icon
          fa-style="regular"
          icon-class="fa-times"
        />
      </button>
    </div>
  </div>
</template>

<style scoped>
.KogDefaultToaster {
  background-color: var(--kogPlatformWhite);
  border-color: var(--kogTransparent);
  border-style: solid;
  border-width: 0;
  border-left-width: 16px;
  border-radius: 12px;
}

.KogDefaultToaster-error {
  border-color: var(--kog-toaster-negative-border-color);
}

.KogDefaultToaster-success {
  border-color: var(--kog-toaster-positive-border-color);
}

.KogDefaultToaster-info {
  border-color: var(--kog-toaster-informative-border-color);
}

.KogDefaultToaster-actionButton:hover {
  text-decoration: underline;
}

.KogDefaultToaster-dismissButton {
  padding: var(--space-xs);
}
</style>
