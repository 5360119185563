import { cloneDeep, isEqual } from 'lodash';

import { fetchUsersList } from '@apis/users.js';

import { getLeafNodeIds } from 'sharedApp/libs/subject-tree-functions.js';
import { fetchSubjectClassDetails } from 'studyApp/api/subject-classes.js';
import {
  fetchSubjectNodesVisibility,
  updateSubjectClassNodeVisibility,
} from 'studyApp/api/subject-nodes-visibility.js';

export default {
  namespaced: true,
  state: {
    currentSubjectClassDetails: {},
    currentSubjectClassTeachers: [],
    subjectNodesVisibilityMap: {},
    editedSubjectNodesVisibilityMap: {},
    isEditingVisibility: false,
    visibilityEditingTransactionToken: null,
  },
  getters: {
    isHiddenNodesModified(state) {
      return !isEqual(state.subjectNodesVisibilityMap, state.editedSubjectNodesVisibilityMap);
    },
    isAllContentHiddenWhileEditing(state) {
      return subjectNodeRoot => {
        const allLeafNodeIds = getLeafNodeIds(subjectNodeRoot);
        function isNodeHidden(leafNodeId) {
          return !state.editedSubjectNodesVisibilityMap[leafNodeId];
        }
        return allLeafNodeIds.every(isNodeHidden);
      };
    },
    isNodeHiddenWhileEditing(state) {
      return node => {
        const leafNodeIds = getLeafNodeIds(node);
        return leafNodeIds.every(
          leafNodeId => state.editedSubjectNodesVisibilityMap[leafNodeId] === false,
        );
      };
    },
    isNodeVisible(state) {
      return nodeId => state.subjectNodesVisibilityMap[nodeId];
    },
  },
  mutations: {
    setCurrentSubjectClassDetails(state, { subjectClassDetails, teachers }) {
      state.currentSubjectClassDetails = subjectClassDetails;
      state.currentSubjectClassTeachers = teachers;
    },
    setSubjectNodesVisibility(state, subjectNodesVisibilityMap) {
      state.subjectNodesVisibilityMap = subjectNodesVisibilityMap;
      state.editedSubjectNodesVisibilityMap = cloneDeep(subjectNodesVisibilityMap);
    },
    hideSubjectNodes(state, subjectNodeIds) {
      subjectNodeIds.forEach(subjectNodeId => {
        state.editedSubjectNodesVisibilityMap[subjectNodeId] = false;
      });
    },
    showSubjectNodes(state, subjectNodeIds) {
      subjectNodeIds.forEach(subjectNodeId => {
        state.editedSubjectNodesVisibilityMap[subjectNodeId] = true;
      });
    },
    startEditingVisibility(state) {
      state.isEditingVisibility = true;
      if (state.visibilityEditingTransactionToken === null) {
        state.visibilityEditingTransactionToken = Date.now();
      }
    },
    stopEditingVisibility(state) {
      state.isEditingVisibility = false;
    },
    resetChangesOnHiddenNodes(state) {
      state.editedSubjectNodesVisibilityMap = cloneDeep(state.subjectNodesVisibilityMap);
    },
    resetVisibilityEditingTransactionToken(state) {
      state.visibilityEditingTransactionToken = null;
    },
  },
  actions: {
    async fetchSubjectClassDetails({ commit, dispatch }, { subjectClassId }) {
      const subjectClassDetails = await fetchSubjectClassDetails(subjectClassId);
      let teachers = [];

      const teacherIds = subjectClassDetails.teacher_user_ids || [];
      if (teacherIds.length > 0) {
        teachers = await fetchUsersList(teacherIds);
      }

      commit('setCurrentSubjectClassDetails', { subjectClassDetails, teachers });
      dispatch('fetchSubjectNodesVisibility', { subjectClassId });
    },
    async fetchSubjectNodesVisibility({ commit }, { subjectClassId }) {
      const result = await fetchSubjectNodesVisibility(subjectClassId);
      commit('setSubjectNodesVisibility', result);
    },
    async saveHiddenSubjectNodes({ state, dispatch }, subjectClassId) {
      await updateSubjectClassNodeVisibility(subjectClassId, state.editedSubjectNodesVisibilityMap);
      dispatch('fetchSubjectNodesVisibility', { subjectClassId });
    },
    toggleNodeVisibility({ state, getters, commit }, subjectNode) {
      const affectedSubjectNodeIds = getLeafNodeIds(subjectNode);
      const isHidden = getters.isNodeHiddenWhileEditing(subjectNode);

      if (isHidden) {
        commit('showSubjectNodes', affectedSubjectNodeIds);
      } else {
        commit('hideSubjectNodes', affectedSubjectNodeIds);
      }
    },
  },
};
