import { isNil } from 'lodash';
import { compile } from 'path-to-regexp';

import { TeacherAssignmentRequest } from '@apis/generated/models.ts';
import { AssignmentsService } from '@apis/generated/services.ts';

import {
  ASSIGNMENT_NOT_FOUND_ERROR_MESSAGE,
  AssignmentNotFoundError,
  AssignmentNotValidError,
  LMS_TOKEN_INVALID_ERROR_MESSAGE,
  LmsTokenInvalidError,
  NO_ACCESS_TOKEN_ERROR_MESSAGE,
  NoAccessTokenError,
  NotSentToIntegrationError,
} from 'sharedApp/const/integrations.ts';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const userAssignmentsToPath = compile('/api/assignments/students/:userId/');

export function fetchAssignmentDetails(assignmentId) {
  try {
    return AssignmentsService.assignmentsRetrieve({ assignmentId });
  } catch (e) {
    let error = 'Something went wrong';
    if (e.response && e.response.data && e.response.data.detail) {
      error = e.response.data.detail;
    }
    throw new Error(error);
  }
}

function handleFetchIntegrationAssignmentError(error) {
  const apiErrorMessage = error?.response?.data?.detail;
  if (isNil(apiErrorMessage)) {
    throw error;
  }
  if (apiErrorMessage === 'Not sent to LMS') {
    throw new NotSentToIntegrationError();
  }
  if (apiErrorMessage === NO_ACCESS_TOKEN_ERROR_MESSAGE) {
    throw new NoAccessTokenError();
  }
  if (apiErrorMessage === ASSIGNMENT_NOT_FOUND_ERROR_MESSAGE) {
    throw new AssignmentNotFoundError();
  }
  if (apiErrorMessage === LMS_TOKEN_INVALID_ERROR_MESSAGE) {
    throw new LmsTokenInvalidError();
  }
  throw new AssignmentNotValidError(apiErrorMessage);
}

export async function fetchIntegrationAssignment(assignmentId, subjectClassId) {
  const url = `/api/assignments/${assignmentId}/${subjectClassId}/integration/`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return handleFetchIntegrationAssignmentError(error);
  }
}

export function updateAssignmentDetails(assignmentId, payload) {
  const patchData = {
    requestBody: payload,
    assignmentId,
  };
  return AssignmentsService.assignmentsPartialUpdate(patchData);
}

export async function fetchUserAssignments({ userId, params }) {
  const url = userAssignmentsToPath({ userId });
  const response = await axios.get(url, { params });
  return response.data;
}

function getLastUsedDeadlineHourMinute(userId, subjectClassId) {
  return {
    deadline_hour:
      localStorage.getItem(`deadline_preferred_hour-${userId}-${subjectClassId}`) || '9',
    deadline_minute:
      localStorage.getItem(`deadline_preferred_minute-${userId}-${subjectClassId}`) || '0',
  };
}

export function createAssignment(
  subjectClassId,
  assignmentType,
  userId,
  subjectNodeIds = [],
  sendToIntegration = false,
) {
  const deadline = getLastUsedDeadlineHourMinute(userId, subjectClassId);
  const requestBody = {
    target_subject_class_id: subjectClassId,
    assignment_type: assignmentType,
    deadline_hour: Number(deadline.deadline_hour),
    deadline_minute: Number(deadline.deadline_minute),
    subject_node_ids: subjectNodeIds,
    send_to_integration: sendToIntegration,
  };
  return AssignmentsService.assignmentsCreate({ requestBody });
}
