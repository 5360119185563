import { fetchStudentsList } from '@apis/subject-class.js';

const namespaced = true;

const mutations = {
  setSubjectClassStudentsList(state, studentsList) {
    state.subjectClassStudentsList = studentsList;
  },
};

const actions = {
  async fetchSubjectClassStudentsList({ commit }, subjectClassId) {
    const subjectClassStudentsList = await fetchStudentsList(subjectClassId);
    commit('setSubjectClassStudentsList', subjectClassStudentsList);
  },
};

const state = {
  subjectClassStudentsList: [],
};

export default {
  namespaced,
  mutations,
  actions,
  state,
};
