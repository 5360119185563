import { createStore } from 'vuex';

import dashboardActivityModule from 'learning/common/store-modules/activity/index.js';
import feedbackModule from 'learning/common/store-modules/feedback/index.js';
import mediaLibraryModule from 'learning/common/store-modules/media-library/index.js';
import notificationsModule from 'learning/common/store-modules/notifications/notifications.js';
import questionsModule from 'learning/common/store-modules/questions.js';
import userModule from 'learning/common/store-modules/user/index.js';

import activityModule from 'studyApp/store/modules/activity.js';
import assignmentModule from 'studyApp/store/modules/assignment.js';
import battlesModule from 'studyApp/store/modules/battles.js';
import bookModule from 'studyApp/store/modules/book.js';
import drivingQuestionModule from 'studyApp/store/modules/driving-question.js';
import exerciseOccasionsModule from 'studyApp/store/modules/exercise-occasions.js';
import feedbackItemModule from 'studyApp/store/modules/feedback-item.js';
import glossaryV2Module from 'studyApp/store/modules/glossary.js';
import notebookModule from 'studyApp/store/modules/notebook.js';
import performanceTaskModule from 'studyApp/store/modules/performance-task.js';
import practiceOccasionModule from 'studyApp/store/modules/practice-occasion.js';
import questionActivityModule from 'studyApp/store/modules/question-activity.js';
import quizModule from 'studyApp/store/modules/quiz.js';
import searchModule from 'studyApp/store/modules/search.js';
import statisticModule from 'studyApp/store/modules/statistic.js';
import subjectClassStudentsModule from 'studyApp/store/modules/subject-class-students.js';
import subjectClassModule from 'studyApp/store/modules/subject-class.js';
import subjectNodeFeatureModule from 'studyApp/store/modules/subject-node-features.js';
import subjectModule from 'studyApp/store/modules/subject.js';
import syllabusModule from 'studyApp/store/modules/syllabus.js';
import teacherGoogleClassroomModule from 'studyApp/store/modules/teacher/google-classroom.js';
import inlineStatisticsModule from 'studyApp/store/modules/teacher/inline-statistics.js';
import teacherInsightsModule from 'studyApp/store/modules/teacher/insights/index.js';
import teacherStudentListModule from 'studyApp/store/modules/teacher/student-list.js';
import teacherSubjectClassModule from 'studyApp/store/modules/teacher/subject-class.js';
import teacherDetailModule from 'studyApp/store/modules/teacher/teacher-detail.js';
import teacherListModule from 'studyApp/store/modules/teacher/teacher-list.js';
import teacherLtqNotesModule from 'studyApp/store/modules/teacher/teacher-ltq-notes.js';
import teacherSubjectModule from 'studyApp/store/modules/teacher/teacher-subject.js';

export default createStore({
  strict: true,
  modules: {
    userModule,
    subjectModule,
    exerciseOccasionsModule,
    assignmentModule,
    statisticModule,
    notificationsModule,
    notebookModule,
    bookModule,
    searchModule,
    feedbackModule,
    mediaLibraryModule,
    activityModule,
    battlesModule,
    glossaryV2Module,
    practiceOccasionModule,
    feedbackItemModule,
    syllabusModule,
    inlineStatisticsModule,
    teacherSubjectClassModule,
    teacherDetailModule,
    teacherInsightsModule,
    teacherListModule,
    teacherStudentListModule,
    teacherSubjectModule,
    teacherGoogleClassroomModule,
    questionsModule,
    subjectClassModule,
    subjectClassStudentsModule,
    drivingQuestionModule,
    teacherLtqNotesModule,
    subjectNodeFeatureModule,
    questionActivityModule,
    performanceTaskModule,
    quizModule,
    dashboardActivityModule,
  },
});
