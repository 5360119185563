export const BLOCK_TYPE = {
  QUESTION: 'question',
  CUSTOM_EDITOR: 'custom_editor',
  CONTENT_BOX: 'content_box',
  DIVIDER: 'divider',
  HEADING: 'heading',
  IMAGE: 'image',
} as const;

export type BlockType = (typeof BLOCK_TYPE)[keyof typeof BLOCK_TYPE];

export const getBlockTypeLabel = (blockType: BlockType) => {
  switch (blockType) {
    case BLOCK_TYPE.QUESTION:
      return 'Question';
    case BLOCK_TYPE.CUSTOM_EDITOR:
      return 'Custom editor';
    case BLOCK_TYPE.CONTENT_BOX:
      return 'Content box';
    case BLOCK_TYPE.DIVIDER:
      return 'Divider';
    case BLOCK_TYPE.HEADING:
      return 'Heading';
    case BLOCK_TYPE.IMAGE:
      return 'Image';
    default:
      throw new Error(`Unknown block type: ${blockType}`);
  }
};
