import * as Sentry from '@sentry/vue';
import { compile } from 'path-to-regexp';

function createToPathFunctions(routeDir) {
  const newRouteDir = { ...routeDir };
  Object.keys(newRouteDir.urls).forEach(endpointName => {
    let jsRegex = newRouteDir.urls[endpointName].js;
    if (jsRegex === undefined) {
      jsRegex = newRouteDir.urls[endpointName].path;
    }
    const toPath = compile(jsRegex);
    newRouteDir.urls[endpointName].toPath = (params, withBase) => {
      if (withBase) {
        return `${newRouteDir.base}${toPath(params)}`;
      }
      return toPath(params);
    };
  });
  return newRouteDir;
}

export const schoolAdminRoutes = {
  base: '/schooladmin/app',
  urls: {
    integrations: { path: '/schools/:school_id/integrations' },
    classList: { path: '/schools/:school_id/classes' },
    classDetails: { path: '/schools/:school_id/classes/:subjectclass_id' },
    studentList: { path: '/schools/:school_id/students' },
    studentDetails: { path: '/schools/:school_id/students/:student_id' },
    teacherList: { path: '/schools/:school_id/teachers' },
    teacherDetails: { path: '/schools/:school_id/teachers/:teacher_id' },
    managebacImport: { path: '/schools/:school_id/managebac' },
    bulkAddStudents: { path: '/schools/:school_id/import-students-csv' },
    bulkAddClasses: { path: '/schools/:school_id/import-classes-csv' },
    yearClasses: { path: '/schools/:school_id/yeargroups' },
    billing: { path: '/schools/:school_id/billing' },
    invoicePdf: { path: '/schools/:school_id/invoices/:invoice_id' },
    gdpr: { path: '/schools/:school_id/gdpr' },
    schoolLogs: { path: '/schools/:school_id/logs' },
  },
};

const apps = {
  schoolAdminApp: createToPathFunctions(schoolAdminRoutes),
};

export function reverse(appName, routeName, paramsObj, withBase = true) {
  try {
    const app = apps[appName];
    return app.urls[routeName].toPath(paramsObj, withBase);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

const SCHOOLADMIN_BASE_PATH = '/api/schooladmin/schools/:schoolId';
const SUBJECT_CLASS_BASE_PATH = `${SCHOOLADMIN_BASE_PATH}/subject_classes/:subjectClassId`;
const SUBJECT_CLASSES_LIST_URL = compile(`${SCHOOLADMIN_BASE_PATH}/subject_classes/`);
const SUBJECT_CLASS_DETAILS_URL = compile(
  `${SCHOOLADMIN_BASE_PATH}/subject_class/:subjectClassId/`,
);
const SUBJECT_CLASS_CREATE_URL = compile(`${SCHOOLADMIN_BASE_PATH}/subject_class/`);
const SUBJECT_CLASS_TEACHERS_LIST_URL = compile(`${SUBJECT_CLASS_BASE_PATH}/teachers/`);
const SUBJECT_CLASS_STUDENTS_LIST_URL = compile(`${SUBJECT_CLASS_BASE_PATH}/students/`);
const SCHOOL_DETAIL_URL = compile(`${SCHOOLADMIN_BASE_PATH}/`);
const SCHOOL_BASE_URL = '/api/schooladmin/schools/';
const SEND_USER_PASSWORD_RESET_EMAIL_URL = '/api/schooladmin/users/user_password_reset/';
const STUDENT_CLASSES_URL = (schoolId, studentId) =>
  `${SCHOOL_BASE_URL}${schoolId}/school_student/${studentId}/subject_classes/`;
const CLASSES_ADD_STUDENTS = (schoolId, subjectClassId) =>
  `/api/kogadmin/schools/${schoolId}/subject_class/${subjectClassId}/add_students/`;
const STUDENT_IN_SCHOOL_URL = (schoolId, studentId) =>
  `${SCHOOL_BASE_URL}${schoolId}/school-student/${studentId}/`;
const TEACHERS_IN_SCHOOL_URL = schoolId => `${SCHOOL_BASE_URL}${schoolId}/school_teachers/`;
const TEACHERS_SUMMARY_URL = schoolId => `${TEACHERS_IN_SCHOOL_URL(schoolId)}summary`;
const TEACHER_IN_SCHOOL_URL = (schoolId, teacherId) =>
  `${SCHOOL_BASE_URL}${schoolId}/school_teacher/${teacherId}/`;
const TEACHER_CLASSES_URL = (schoolId, teacherId) =>
  `${SCHOOL_BASE_URL}${schoolId}/school_teacher/${teacherId}/subject_classes/`;
const TEACHER_CLASS_ASSOCIATION_URL = (schoolId, teacherId, classId) =>
  `/api/schooladmin/schools/${schoolId}/school_teachers/${teacherId}/subject_class/${classId}/`;
const SCHOOL_STUDENTS_URL = compile(`${SCHOOLADMIN_BASE_PATH}/school-students/`);
const SCHOOL_STUDENTS_SUMMARY_URL = compile(`${SCHOOLADMIN_BASE_PATH}/school_students/summary/`);
const UPDATE_SCHOOL_ADMINISTRATOR_URL = compile(
  `${SCHOOLADMIN_BASE_PATH}/school_administrator/:userId/`,
);
const UPDATE_SCHOOL_PRIMARY_ADMINISTRATOR_URL = compile(
  `${SCHOOLADMIN_BASE_PATH}/school_administrator/:userId/primary/`,
);

const INTEGRATION_SCHOOL_URL = compile(`${SCHOOLADMIN_BASE_PATH}/integrations/`);
const INTEGRATION_SCHOOL_DETAIL_URL = compile(
  `${SCHOOLADMIN_BASE_PATH}/integrations/:integrationId/`,
);
const INTEGRATION_SCHOOL_CLASSES_URL = compile(
  `${SCHOOLADMIN_BASE_PATH}/integrations/:integrationSlug/classes/`,
);

const YEARCLASSES_LIST_URL = compile(`${SCHOOLADMIN_BASE_PATH}/yearclasses/`);
const YEARCLASSES_DETAILS_URL = compile(`${SCHOOLADMIN_BASE_PATH}/yearclass/:yearClassId/`);

const SUBSCRIPTION_INTEGRATION_URL = compile(`${SCHOOLADMIN_BASE_PATH}/subscriptions/`);
const UPCOMING_SUBSCRIPTION_INTEGRATION_URL = compile(
  `${SCHOOLADMIN_BASE_PATH}/upcoming_subscriptions/`,
);
const SUBSCRIPTION_RENEWAL_URL = compile(`${SCHOOLADMIN_BASE_PATH}/subscription_renewal/`);
const SUBSCRIPTION_RENEWAL_PRICE_URL = compile(
  '/api/schooladmin/subscription_renewal_price/:subscriptionId/',
);
const SUBSCRIPTION_INVOICES_URL = compile(`${SCHOOLADMIN_BASE_PATH}/invoices/`);

const SUBJECTS_LIST_URL = '/api/schooladmin/subjects/';

export const API_URLS = {
  STUDENT_IN_SCHOOL_URL,
  TEACHERS_IN_SCHOOL_URL,
  TEACHERS_SUMMARY_URL,
  TEACHER_IN_SCHOOL_URL,
  SCHOOL_DETAIL_URL,
  STUDENT_CLASSES_URL,
  CLASSES_ADD_STUDENTS,
  SEND_USER_PASSWORD_RESET_EMAIL_URL,
  TEACHER_CLASSES_URL,
  TEACHER_CLASS_ASSOCIATION_URL,
  SUBJECT_CLASSES_LIST_URL,
  SUBJECT_CLASS_DETAILS_URL,
  SUBJECT_CLASS_CREATE_URL,
  SUBJECT_CLASS_TEACHERS_LIST_URL,
  SUBJECT_CLASS_STUDENTS_LIST_URL,
  SCHOOL_STUDENTS_URL,
  SCHOOL_STUDENTS_SUMMARY_URL,
  UPDATE_SCHOOL_ADMINISTRATOR_URL,
  UPDATE_SCHOOL_PRIMARY_ADMINISTRATOR_URL,
  INTEGRATION_SCHOOL_URL,
  INTEGRATION_SCHOOL_DETAIL_URL,
  INTEGRATION_SCHOOL_CLASSES_URL,
  YEARCLASSES_LIST_URL,
  YEARCLASSES_DETAILS_URL,
  SUBSCRIPTION_INTEGRATION_URL,
  UPCOMING_SUBSCRIPTION_INTEGRATION_URL,
  SUBSCRIPTION_RENEWAL_URL,
  SUBSCRIPTION_RENEWAL_PRICE_URL,
  SUBSCRIPTION_INVOICES_URL,
  SUBJECTS_LIST_URL,
};

export const ROUTES = {
  ADMIN_APP_SCHOOLS: '/admin/app/schools/',
};
