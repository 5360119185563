import * as Sentry from '@sentry/vue';

import { fetchSchoolSummary } from '@apis/schools.js';
import { fetchSubjectClass } from '@apis/subject-class';

import schoolAdminApi from 'schoolAdminApp/api/index.js';

const namespaced = true;

function getSubjectBySlug(slug) {
  return window.KOG.STAFF_CONSTANTS.SUBJECTS_BY_SLUG[slug];
}

const mutations = {
  setSubject(state, subject) {
    state.subject = subject;
  },
  setSubjectClass(state, subjectClassObj) {
    state.subjectClass = subjectClassObj;
  },
};

const actions = {
  fetchSubject({ commit }, { subjectSlug }) {
    const subject = getSubjectBySlug(subjectSlug);
    commit('setSubject', subject);
  },
  async saveClassDetails({ dispatch, commit }, { schoolId, subjectClassId, name, endDate }) {
    const data = { name, end_date: endDate };
    const result = await schoolAdminApi.updateSubjectClass(schoolId, subjectClassId, data);
    dispatch(
      'teacherSubjectClassModule/fetchSubjectClass',
      { subjectClassId, useCache: false },
      { root: true },
    );
    return result;
  },
  async fetchSchoolSummary() {
    const data = await fetchSchoolSummary();
    return data;
  },
  async fetchSubjectClass({ commit }, { subjectClassId, useCache = true }) {
    try {
      const response = await fetchSubjectClass(subjectClassId, useCache);
      commit('setSubjectClass', response);
    } catch (error) {
      Sentry.captureMessage(`fetchSubjectClass: ${error}`);
    }
  },
};

const state = {
  subject: null,
  subjectClass: null,
};

export default {
  namespaced,
  mutations,
  actions,
  state,
};
