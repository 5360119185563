import { fetchExamStyleQuestionsByTopic } from '@apis/questions.js';
import { fetchSubjectDetails } from '@apis/subject.js';

import { getSubjectNodeMap } from 'sharedApp/libs/subject-tree-functions.js';
import {
  isExamStyleType,
  isQuestionType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';

const namespaced = true;

const uniqueQuestionIdsList = esqsPerTopic => {
  const uniqueQuesIds = new Set();
  esqsPerTopic.forEach(topic => {
    topic.questions.forEach(question => {
      uniqueQuesIds.add(question.id);
    });
  });
  return Array.from(uniqueQuesIds);
};

const buildEsqCountPerTopic = esqsPerTopic =>
  esqsPerTopic.reduce((acc, topic) => {
    acc[topic.node_id] = topic.questions.length;
    return acc;
  }, {});

const mutations = {
  setSubject(state, subject) {
    state.subject = subject;
  },
  setSubjectMap(state, subjectMap) {
    state.subjectMap = subjectMap;
  },
  setQuestionCounts(state, questionCounts) {
    state.questionCounts = questionCounts;
  },
  setEsqsPerTopic(state, esqsPerTopic) {
    state.esqsPerTopic = esqsPerTopic;
  },
};

const actions = {
  async fetchSubject({ commit }, { subjectId, useCache = true }) {
    const subject = await fetchSubjectDetails(subjectId, useCache);
    const subjectMap = getSubjectNodeMap(subject.subject_tree[0]);
    commit('setSubject', subject);
    commit('setSubjectMap', subjectMap);
  },
  async fetchEsqIdsPerTopic({ commit }, subjectId) {
    const params = {
      assignment: true,
      practice: false,
    };
    const esqsPerTopic = await fetchExamStyleQuestionsByTopic(subjectId, params);
    commit('setEsqsPerTopic', esqsPerTopic);
  },
  async setQuestionCountsForAssignmentType({ commit, state, dispatch }, assignmentType) {
    if (isQuestionType(assignmentType)) {
      commit('setQuestionCounts', state.subject.question_counts);
    } else if (isExamStyleType(assignmentType)) {
      await dispatch('fetchEsqIdsPerTopic', state.subject.id);
      const esqCountPerTopic = buildEsqCountPerTopic(state.esqsPerTopic);
      const index = state.subject.subject_tree[0].id;
      esqCountPerTopic[index] = uniqueQuestionIdsList(state.esqsPerTopic).length;
      commit('setQuestionCounts', esqCountPerTopic);
    }
  },
};

const getters = {};

const state = {
  subject: null,
  subjectMap: {},
  questionCounts: {},
  esqsPerTopic: [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
